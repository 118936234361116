/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */



function _craftContentAd() {	
	document.write(_freehubIframeAd());
	return;
}

function _freehubOxInsert(zoneIds) {	
	var zoneid = _freehubGetAdSizeId(zoneIds);
	_freehubGetAdScriptEmbed(zoneid);
}

function _freehubGetAdScriptEmbed(zoneid) {
	var src = _freehubGetAdScriptUrl(zoneid, 'ajs');
	document.write ("<scr"+"ipt type='text/javascript' src='"+src);
	document.write ("'><\/scr"+"ipt>");
}


function _freehubGetAdScriptUrl(zoneid, deliveryUrl) {	
	var m3_u = (location.protocol=='https:'?'https://a.freehubmag.com/delivery/'+deliveryUrl+'.php':'http://a.freehubmag.com/delivery/'+deliveryUrl+'.php');
	var m3_r = Math.floor(Math.random()*99999999999);
	if (!document.MAX_used) document.MAX_used = ',';
	var str = m3_u;
	str += ("?zoneid="+zoneid+"&amp;block=1");
	str += ('&amp;cb=' + m3_r);
	if (document.MAX_used != ',') str += ("&amp;exclude=" + document.MAX_used);
	str +=  (document.charset ? '&amp;charset='+document.charset : (document.characterSet ? '&amp;charset='+document.characterSet : ''));
	str +=  ("&amp;loc=" + escape(window.location));
	if (document.referrer) str += ("&amp;referer=" + escape(document.referrer));
	if (document.context) str += ("&context=" + escape(document.context));
	if (document.mmm_fo) str += ("&amp;mmm_fo=1");

	return str;
}


function _freehubIframeAd() {
	var zid = _freehubGetAdSizeId([21,19]);	
	return "<div class='responsive-ad-strip z-"+zid+"'>"+_freehubGetIframeStr(zid)+'</div>';
}


function _freehubGetIframeSrc(zoneIds) {
	var zid = _freehubGetAdSizeId(zoneIds);
	return _freehubGetIframeStr(zid);
}

function _freehubGetIframeStr(zid) {
		var d = new Date();
		var cb = d.getTime();
		var id = 'a'+cb;	
	//id='"+id+"' name='"+id+"'
		var str = "<div class='ox-wrapper'><iframe seamless class='freehub-ox' src='//a.freehubmag.com/delivery/freehub.php?zoneid="+zid+"&amp;cb="+cb+"' frameborder='0' scrolling='no' width='100%' height='100%'><a href='//a.freehubmag.com/delivery/ck.php?n=a4854d85&amp;cb="+cb+"' target='_blank'><img src='//a.freehubmag.com/delivery/avw.php?zoneid="+zid+"&amp;cb="+cb+"&amp;n=a4854d85' border='0' alt='' /></a></iframe></div>";

		return str;
}


function _freehubGetAdSizeId(zoneIds) {
	var width = window.innerWidth 
        || document.documentElement.clientWidth 
        || document.body.clientWidth;

     var zid;
    if (width <= 600) {
    	zid = zoneIds[0];
    } else {
    	zid = zoneIds[1];
    }
    /*else if (width <= 825) {
    	zid = zoneIds[1];
    } else if (width <= 1200) {
    	zid = zoneIds[2];
    } else {
    	zid = zoneIds[3];
    }
    */

    if (!zid) {
    	zid = zoneIds[zoneIds.length-1];
    }

    return zid;
}

/*
* Pass in array of zone ids small to large (home hero is an extra option)
*/
function _freehubRevive(zoneIds) {
	//return OA_show(_freehubGetAdSizeId(zoneIds));
	// TOOD make iframe work with hero and footer 
	document.write(_freehubGetIframeSrc(zoneIds));
}




// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
// Place your code here.
(function ($, Drupal, window, document, undefined) {


// To understand behaviors, see https://drupal.org/node/756722#behaviors
Drupal.behaviors.craft_theme = {
  attach: function(context, settings) {

  		$('.swiper').each(function() {
          // modify markup for swiper (TODO, do this in CMS template)
          var $view = $(this).find('.view');
          $view.addClass('swiper').find('.view-content').addClass('swiper-wrapper').find('.views-row').addClass('swiper-slide').find('.content').append('<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>');
          $view.find('.view-content').after('<div class="swiper-pagination"></div>');

          var swiper = new Swiper($view[0], {
             navigation: {
               nextEl: ".swiper-button-next",
               prevEl: ".swiper-button-prev",
             },
             pagination: {
               el: ".swiper-pagination",
               clickable: true,
             },
           });
        });

  		$('#mobile-menu-burger').click(function() {
  			$('#site-header').toggleClass('open');
  		});

  		
  		$('.node-product.view-mode-full', context).each(function () {
      		$(this).find('.field-image-cache a').click(function(e) {
				e.preventDefault();
				var $t = $(this);
				if ($t.hasClass('active')) {
					return;
				}
				var href = $t.attr('href');
				var $p = $t.parents('.image-box');
				$p.find('.current-image img').attr('src',href);
				$p.find('a.active').removeClass('active');
				$t.addClass('active');
			}).first().addClass('active');

			$(this).find('#edit-attributes-9').change(function(e) {
				var color = $(this).find(':selected').text();
				var $thumb = $(this).parents('.node-product').find('.field-image-cache img[alt*="'+color+'"]');
				$thumb.parent().click();
			});
    	});


  		$('.entity-shopify-product.view-mode-full', context).each(function () {
  				var $imagebox = $('<div class="image-box"><div class="current-image"></div><div class="image-thumbs columns"></div></div>');
  				$imagebox.find('.image-thumbs').append($(this).find('.field-shopify-product-images'));
  				$(this).find('.group-images').append($imagebox);
      		$imagebox.find('a').click(function(e) {
						e.preventDefault();
						var $t = $(this);
						if ($t.hasClass('active')) {
							return;
						}
						var href = $t.attr('href');
						var $p = $t.parents('.image-box');
						$p.find('.current-image img').attr('src',href);
						$p.find('a.active').removeClass('active');
						$t.addClass('active');
					}).first().addClass('active');
					var href = $imagebox.find('a').first().attr('href');
					$imagebox.find('.current-image').append('<img src="'+href+'" />');				

					var $imgcount = $imagebox.find('a');
					if (!$imgcount[1]) {
						$imagebox.find('.image-thumbs').remove();
					}
    	});
  		


  }
};


})(jQuery, Drupal, this, this.document);
